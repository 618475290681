import ApiEndPoints from "../Enums/ApiEndPoint.enum";

import axios from "./Configuration/apiConfiguration";

const getVinDecode = (data: any) =>
    new Promise((resolve, reject) => {
        axios
            .get(`${ApiEndPoints.chromeData}/vin-decode/?vin=${data?.vin}`, data)
            .then((res) => {
                resolve({ status: true, data: res.data });
            })
            .catch((apiFailed) => {
                reject(apiFailed);
            });
    });
const getYear = () =>
    new Promise((resolve, reject) => {
        axios
            .get(`${ApiEndPoints.chromeData}/year`, {})
            .then((res) => {
                resolve({ status: true, data: res.data });
            })
            .catch((apiFailed) => {
                reject(apiFailed);
            });
    });

const getMake = (data: any) =>
    new Promise((resolve, reject) => {
        axios
            .get(`${ApiEndPoints.chromeData}/make?year=${data}`, {})
            .then((res) => {
                resolve({ status: true, data: res.data });
            })
            .catch((apiFailed) => {
                reject(apiFailed);
            });
    });

const getModel = (data: any) =>
    new Promise((resolve, reject) => {
        axios
            .get(`${ApiEndPoints.chromeData}/model?year=${data.year}&makeCode=${data.makeCode}`, {})
            .then((res) => {
                resolve({ status: true, data: res.data });
            })
            .catch((apiFailed) => {
                reject(apiFailed);
            });
    });

export default { getVinDecode, getYear, getMake, getModel };
