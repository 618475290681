import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Box } from "@mui/material";

import NavBar from "./Components/NavBar";
import HoC from "./Components/reusableComponents";
import SideBar from "./Components/reusableComponents/SideBar";
import SpinnerComponent from "./Components/reusableComponents/Spinner";
import RoutesEnum from "./Enums/Routes.enum";
import { useAppDispatch, useAppSelector } from "./Redux/hooks";
import { updateUser } from "./Redux/Reducer";
import API from "./API";
import RoutesPage from "./Routes";

const App = () => {
    const navigate = useNavigate();
    const user = useAppSelector((state) => state.user);
    const { open } = useAppSelector((state: any) => state.modal);

    const location = useLocation();

    const currentPath = location.pathname;

    const [loading, setLoading] = useState(false);

    const dispatch = useAppDispatch();
    // const userData: any = localStorage.getItem("user");
    const userIdLoc: any = localStorage.getItem("userId");
    // const LoggedUser = JSON.parse(userData);
    const userId = JSON.parse(userIdLoc);

    const getLoggedInUser = async () => {
        try {
            if (currentPath !== "/chat") {
                setLoading(true);
                const res: any = await API.Users.getUsersByUserID(userId);
                if (!res?.data?.status) {
                    setLoading(false);
                    localStorage.removeItem("user");
                    localStorage.removeItem("userId");
                    navigate(RoutesEnum.home);
                } else {
                    setLoading(false);
                    localStorage.setItem("user", JSON.stringify(res?.data?.data));
                    dispatch(updateUser(res?.data?.data));
                    navigate(RoutesEnum.dashboard);
                }
            }
        } catch (error) {
            setLoading(false);
        }
    };
    React.useEffect(() => {
        getLoggedInUser();
    }, []);

    return (
        <Box component="div">
            {loading ? (
                <SpinnerComponent open={loading} />
            ) : (
                <>
                    {!user?.UserID && currentPath !== "/chat" && <NavBar />}
                    {user?.UserID && currentPath !== "/chat" ? (
                        <SideBar menuData={user?.Screens}>
                            <RoutesPage />
                        </SideBar>
                    ) : (
                        <RoutesPage />
                    )}
                </>
            )}

            {open && <HoC.Modal />}
        </Box>
    );
};

export default App;
